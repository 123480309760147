<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
      >
        <b-card no-body class="history-top">
          <b-card-body>
            <div class="history-text">
              <feather-icon
                class="history-icon"
                icon="ClockIcon"
              />
              <span class="history-title">
                History
              </span>
            </div>
            <div class="history-selects d-none d-md-flex">
              <v-select
                class="select-local"
                v-model="selectedLocal"
              />
              <v-select
                class="select-month"
                v-model="selectedMonth"
                :options="monthRange"
              />
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body class="d-flex d-md-none">
          <b-card-body>
            <b-row>
              <b-col cols="6">
                <v-select
                v-model="selectedLocal"
              />
              </b-col>
              <b-col cols="6">
                <v-select
                v-model="selectedMonth"
                :options="monthRange"
              />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body class="offset-1 col-10">
            <b-table
              :items="items"
              :fields="fields"
              striped
              responsive
              class="history-list mb-0"
            >
              <template #cell(avatar)="data">
                <b-avatar :src="data.value" />
              </template>
            </b-table>
            <div class="d-flex flex-wrap justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="pagination-btn mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <template #code>
        {{ codeRowDetailsSupport }}
      </template>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BCardBody,
  BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
  BTable, BFormCheckbox, BAvatar, BBadge, BPagination
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { codeRowDetailsSupport } from './code'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup, 
    BFormInput, 
    BInputGroup, 
    BInputGroupPrepend,
    BTable, 
    BFormCheckbox, 
    BAvatar, 
    BBadge,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 1,
      totalRows: 3,
      currentPage: 1,
      selectedMonth: 'Último mês',
      monthRange: ['Último mês', 'Últimos 6 meses', '2021', '2020'],
      selectedLocal: 'Local',
      fields: [{ key: 'avatar', label: '' }, {key: 'full_name', label: 'NOME'}, {key: 'actions', label: 'AÇÕES EFETUADAS'}, { key: 'date', label: 'DATA DA INTERVENÇÃO' }],
      /* eslint-disable global-require */
      items: [
        {
          id: 1,
          avatar: require('@/assets/images/avatars/10-small.png'),
          full_name: "Korrie O'Crevy",
          actions: 'Temperatura alterada para 18º',
          date: '23-01-2022 12:00',
        },
        {
          id: 2,
          avatar: require('@/assets/images/avatars/1-small.png'),
          full_name: 'Bailie Coulman',
          actions: 'Temperatura alterada para 18º',
          date: '23-01-2022 12:00',
        },
        {
          id: 3,
          avatar: require('@/assets/images/avatars/9-small.png'),
          full_name: 'Stella Ganderton',
          actions: 'Temperatura alterada para 18º',
          date: '23-01-2022 12:00',
        },
      ],
      codeRowDetailsSupport,
    }
  },
}
</script>